<script>
import {required} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

/* COMPOSANTS */
import AkInputText from '@components/v2/input/AkInputText';
import AkDialog from '@components/general/AkDialog';
import GaView from '@components/v2/layout/GaView';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import geoSectorService from '@services/geoSectorService';
import AkButtonAction from '../../components/v2/general/AkButtonAction.vue';
import GaListView from '../../components/v2/layout/GaListView.vue';
import AkDatatablePagination from '../../components/v2/general/AkDatatablePagination.vue';
import {Icon} from '@iconify/vue';

export default {
  components: {Icon, AkDatatablePagination, GaListView, AkButtonAction, GaView, AkInputText, AkDialog},
  mixins: [randomRef, loaderMixin, messageMixin, utilsMixin],
  metaInfo() {
    return {
      title: 'settings.geosector',
    };
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      list: [],
      item: {},
      loading: false,
    };
  },
  validations() {
    return {
      item: {
        label: {required},
      },
    };
  },
  mounted() {
    this.loading = true;
    geoSectorService.sectors().then((data) => {
      this.list = data;
      this.loading = false;
    });
  },
  methods: {
    create() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.$refs.dialogCreate.init();
        return;
      }

      geoSectorService
        .create(this.item)
        .then((data) => {
          this.success(this.$t('geosector.added'));
          this.list.push(data);
        })
        .finally(() => {
          this.$refs.dialogCreate.hide();
          this.item = {};
        });
    },
    update() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.$refs.dialogEdit.init();
        return;
      }

      geoSectorService
        .update(this.item)
        .then((data) => {
          this.replaceInListById(this.list, data);
          this.success(this.$t('geosector.updated'));
        })
        .finally(() => {
          this.$refs.dialogEdit.hide();
        });
    },
    delete() {
      geoSectorService
        .delete(this.item)
        .then((data) => {
          this.removeInListById(this.list, data.id);
          this.success(this.item.label + ' a bien été supprimé');
        })
        .catch((e) => {
          let content = 'Une erreur est survenue durant la suppression';
          if (e.response) {
            content = 'Une erreur est survenue durant la suppression : ' + e.response.data.error;
          }
          this.addError(content);
        })
        .finally(() => {
          this.$refs.dialogDelete.hide();
        });
    },
    openCreateDialog() {
      this.item = {};
      this.$refs.dialogCreate.show();
    },
    openEditDialog(item) {
      this.item = {...item};
      this.$refs.dialogEdit.show();
    },
    openDeleteDialog(item) {
      this.item = item;
      this.$refs.dialogDelete.show();
    },
  },
};
</script>

<template>
  <GaView :title="$t('settings.geosector')">
    <template #action>
      <div class="flex justify-end">
        <AkButtonAction little :label="$t('add')" @click="openCreateDialog()"> </AkButtonAction>
      </div>
    </template>

    <template #content>
      <GaListView>
        <template #content>
          <AkDatatablePagination :value="list" :loading="loading" :lazy="false">
            <template #empty>
              {{ $t('geosector.empty') }}
            </template>

            <Column :header="$t('item_label.label')" field="label"></Column>
            <Column style="width: 100px">
              <template #body="slotProps">
                <div class="flex justify-end gap-2">
                  <span class="hover:cursor-pointer" @click="openEditDialog(slotProps.data)">
                    <i class="ga-icon ga-edit text-2xl text-primary" />
                  </span>
                  <span class="hover:cursor-pointer" @click="openDeleteDialog(slotProps.data)">
                    <i class="ga-icon ga-trash text-2xl text-redGa" />
                  </span>
                </div>
              </template>
            </Column>
          </AkDatatablePagination>
        </template>
      </GaListView>
    </template>

    <template #extra>
      <AkDialog
        ref="dialogCreate"
        :title="$t('geosector.add')"
        :validate-label="$t('add')"
        width="450px"
        @validate="create()">
        <AkInputText v-model="item.label" :label="$t('geosector.label')" :validator="v$.item.label" />
      </AkDialog>

      <AkDialog
        ref="dialogEdit"
        :title="$t('geosector.update')"
        :validate-label="$t('update')"
        width="450px"
        @validate="update()">
        <AkInputText v-model="item.label" :label="$t('geosector.label')" :validator="v$.item.label" />
      </AkDialog>

      <AkDialog
        ref="dialogDelete"
        :cancel-label="$t('no')"
        :title="$t('delete_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.delete()">
        <div class="flex items-center gap-2">
          <Icon icon="fluent:warning-20-regular" class="text-redGa" width="26" />
          <span class="text-dark font-normal">{{ $t('geosector.confirm_delete') }}</span>
        </div>
      </AkDialog>
    </template>
  </GaView>
</template>
