<script>
import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

/* COMPOSANTS */
import AkInputText from '@components/v2/input/AkInputText';
import AkDialog from '@components/general/AkDialog';
import GaView from '@components/v2/layout/GaView';
import AkButtonAction from '@components/v2/general/AkButtonAction.vue';
import GaListView from '@components/v2/layout/GaListView.vue';
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination.vue';
import {Icon} from '@iconify/vue';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import poleService from '@services/poleService';

export default {
  components: {Icon, AkDatatablePagination, GaListView, AkButtonAction, GaView, AkInputText, AkDialog},
  mixins: [randomRef, loaderMixin, messageMixin, utilsMixin],
  metaInfo() {
    return {
      title: 'settings.poles',
    };
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      loading: true,
      list: [],
      item: {},
    };
  },
  validations() {
    return {
      item: {
        name: {required},
        technicalKey: {notUsed: this.technicalKeyNotUsed},
      },
    };
  },
  computed: {
    otherTechnicalKeys() {
      return this.list.filter((elem) => elem.id !== this.item.id).map((elem) => elem.technicalKey);
    },
  },
  mounted() {
    poleService.poles().then((data) => {
      this.list = data;
      this.loading = false;
    });
  },
  methods: {
    create() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.$refs.dialogCreate.init();
        return;
      }
      poleService
        .create(this.item)
        .then((data) => {
          this.list.push(data);
          this.success(this.$t('pole.added'));
        })
        .finally(() => {
          this.$refs.dialogCreate.hide();
          this.item = {};
        });
    },
    update() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.$refs.dialogEdit.init();
        return;
      }
      poleService
        .update(this.item)
        .then((data) => {
          this.replaceInListById(this.list, data);
          this.success(this.$t('pole.updated'));
        })
        .finally(() => {
          this.$refs.dialogEdit.hide();
        });
    },
    delete() {
      poleService
        .delete(this.item)
        .then((data) => {
          this.removeInListById(this.list, data.id);
          this.success(this.item.name + ' a bien été supprimé');
        })
        .catch((e) => {
          if (e.response.status === 406) {
            this.addError("Le pole ne peut pas être supprimé parce qu'il est déjà utilisé");
          } else {
            this.addError(this.$t('unknow_error'));
          }
        })
        .finally(() => {
          this.$refs.dialogDelete.hide();
        });
    },

    openCreateDialog() {
      this.item = {};
      this.$refs.dialogCreate.show();
    },
    openEditDialog(item) {
      this.item = {...item};
      this.$refs.dialogEdit.show();
    },
    openDeleteDialog(item) {
      this.item = item;
      this.$refs.dialogDelete.show();
    },

    technicalKeyNotUsed(key) {
      return !this.otherTechnicalKeys.includes(key);
    },
  },
};
</script>

<template>
  <GaView :ref="ref" :title="$t('settings.poles')">
    <template #action>
      <div class="flex justify-end">
        <AkButtonAction little @click="openCreateDialog()" :label="$t('add')"></AkButtonAction>
      </div>
    </template>

    <template #content>
      <GaListView>
        <template #content>
          <AkDatatablePagination :loading="loading" :value="list" :lazy="false">
            <template #empty>
              {{ $t('pole.empty') }}
            </template>

            <Column :header="$t('pole.name')">
              <template #body="slotProps">
                {{ slotProps.data.name }}
              </template>
            </Column>
            <Column :header="$t('pole.technicalKey')">
              <template #body="slotProps">
                {{ slotProps.data.technicalKey }}
              </template>
            </Column>
            <Column style="width: 100px">
              <template #body="slotProps">
                <div class="flex justify-end gap-2">
                  <span class="hover:cursor-pointer" @click="openEditDialog(slotProps.data)">
                    <i class="ga-icon ga-edit text-2xl text-primary" />
                  </span>
                  <span class="hover:cursor-pointer" @click="openDeleteDialog(slotProps.data)">
                    <i class="ga-icon ga-trash text-2xl text-redGa" />
                  </span>
                </div>
              </template>
            </Column>
          </AkDatatablePagination>
        </template>
      </GaListView>
    </template>

    <template #extra>
      <AkDialog
        ref="dialogCreate"
        :title="$t('pole.add')"
        :validate-label="$t('add')"
        width="450px"
        @validate="create()">
        <div class="form-row">
          <AkInputText v-model="item.name" :label="$t('pole.name')" :validator="v$.item.name" class-name="col-12" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogEdit"
        :title="$t('pole.update')"
        :validate-label="$t('update')"
        width="450px"
        @validate="update()">
        <div class="flex flex-col gap-2">
          <AkInputText v-model="item.name" :label="$t('pole.name')" :validator="v$.item.name" />
          <AkInputText
            v-model="item.technicalKey"
            :disabled="true"
            :label="$t('pole.technicalKey')"
            :validator="v$.item.technicalKey" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogDelete"
        :cancel-label="$t('no')"
        :title="$t('pole.delete')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.delete()">
        <div class="flex items-center gap-2">
          <Icon icon="fluent:warning-20-regular" class="text-redGa" width="26" />
          <span class="text-dark font-normal">{{ $t('pole.confirm_delete') }}</span>
        </div>
      </AkDialog>
    </template>
  </GaView>
</template>
