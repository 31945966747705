<script>
/* MIXINS */
import missionMixin from '@mixins/missionMixin';

export default {
  mixins: [missionMixin],
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    statusClass() {
      if (this.status) {
        return this.status.toLowerCase().replace('_', '-');
      } else {
        return 'anomaly';
      }
    },
  },
  methods: {},
};
</script>

<template>
  <div class="prestation-status-badge" :class="`--${statusClass}`">
    {{ statusDisplay(status?.replace(' ', '')) }}
  </div>
</template>

<style lang="scss" scoped>
.m-auto .prestation-status-badge {
  margin: auto;
}

.prestation-status-badge {
  @apply rounded-[32px] text-white flex items-center px-4 py-[5px] w-fit gap-2;

  &.--proposed,
  &.--partially-accepted {
    @apply bg-orangeLightGa;
  }

  &.--to-plan {
    @apply bg-orangeGa;
  }

  &.--accepted,
  &.--todo,
  &.--to-do,
  &.--doing {
    @apply bg-greenLightGa;
  }

  &.--canceled {
    @apply bg-redGa;
  }

  &.--completed,
  &.--finish {
    @apply bg-greenGa;
  }

  &.--litigation,
  &.--anomaly {
    @apply bg-redGa;
  }
}
</style>
