<script>
/* COMPOSANTS */
import GaView from '@components/v2/layout/GaView';
import GaContentView from '@components/v2/layout/GaContentView';
import AkButtonAction from '@components/v2/general/AkButtonAction.vue';
import AkButtonActionValidation from '@components/v2/general/AkButtonActionValidation.vue';
import LitigeItemList from '@views/litige/components/LitigeItemList.vue';
import LitigeUpdateSidebar from '@views/litige/sidebar/LitigeUpdateSidebar';
import LitigeProcessSidebar from '@views/litige/sidebar/LitigeProcessSidebar';
import LitigeRefusalSidebar from '@views/litige/sidebar/LitigeRefusalSidebar';
import LitigeWonSidebar from '@views/litige/sidebar/LitigeWonSidebar';
import LitigeLostSidebar from '@views/litige/sidebar/LitigeLostSidebar';

/* MIXINS */
import backMixin from '@mixins/backMixin';
import messageMixin from '@mixins/messageMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import loaderMixin from '@mixins/loaderMixin';
import litigeStatusConst from '@mixins/const/litigeStatusConst';

/* SERVICES */
import litigeService from '@services/litigeService';

export default {
  components: {
    GaView,
    GaContentView,
    AkButtonAction,
    AkButtonActionValidation,
    LitigeItemList,
    LitigeUpdateSidebar,
    LitigeProcessSidebar,
    LitigeRefusalSidebar,
    LitigeWonSidebar,
    LitigeLostSidebar,
  },
  mixins: [backMixin, loaderMixin, messageMixin, permissionsMixin, utilsMixin, litigeStatusConst],
  metaInfo() {
    return {
      title: 'litige.details',
    };
  },
  data() {
    return {
      litige: undefined,
      submitted: false,
    };
  },
  computed: {
    isStatusATraiter() {
      return this.litige && this.litige.status === 'A_TRAITER';
    },
    isStatusEnCours() {
      return this.litige && this.litige.status === 'EN_COURS';
    },
    isStatusResolu() {
      return this.litige && this.litige.status === 'RESOLU';
    },
    isResolutionComplete() {
      return this.litige && this.litige.resolution === 'COMPLETE';
    },
    isResolutionValidated() {
      return this.litige && this.litige.resolution === 'VALIDATED';
    },
    isResolutionRefused() {
      return this.litige && this.litige.resolution === 'REFUSED';
    },
    isResolutionGagne() {
      return this.litige && this.litige.resolution === 'GAGNE';
    },
    isResolutionPerdu() {
      return this.litige && this.litige.resolution === 'PERDU';
    },
    hasLitigeItem() {
      return this.litige && this.litige.litigeItemList?.length > 0;
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    // REFRESH

    refresh() {
      this.showTotalLoaderGuestadom();
      litigeService
        .litige(this.$route.params.litigeId)
        .then((data) => {
          this.litige = data;
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.hideLoader();
          this.initGoBack();
        });
    },

    // VALIDER

    validateComplete() {
      this.submitted = true;

      const litigeValidation = {
        id: this.litige.id,
        validated: true,
        refusalComment: '',
      };

      litigeService
        .stepValidate(litigeValidation)
        .then((data) => {
          this.success(this.$t('litige.complete_validated'));
          this.submitted = false;
          this.refresh();
        })
        .catch((e) => {
          this.addError(this.$t('error.' + e.response.data.error));
          this.submitted = false;
        });
    },

    // SIDEBAR

    litigeUpdated() {
      this.refresh();
    },
    toggleUpdateSidebar() {
      this.$refs.litigeUpdateSidebar.show(this.litige);
    },
    openProcessSidebar() {
      this.$refs.litigeProcessSidebar.show(this.litige);
    },
    openRefusalSidebar() {
      this.$refs.litigeRefusalSidebar.show(this.litige);
    },
    openWonSidebar() {
      this.$refs.litigeWonSidebar.show(this.litige);
    },
    openLostSidebar() {
      this.$refs.litigeLostSidebar.show(this.litige);
    },
  },
};
</script>

<template>
  <GaView details v-if="this.litige">
    <template #action>
      <div class="flex flex-row gap-4">
        <!-- A traiter -->
        <AkButtonAction
          v-if="this.isStatusATraiter || this.isResolutionRefused"
          :label="$t('litige.process')"
          little
          secondary
          @click="openProcessSidebar()" />

        <!-- Validation / Refus du dossier -->
        <AkButtonActionValidation
          v-if="this.isGas() && this.isResolutionComplete"
          :label="$t('litige.validate')"
          class="btn-action bg-greenGa xs"
          validationPopup
          widthPopup="420px"
          :titlePopup="$t('confirm_dialog')"
          :messagePopup="$t('litige.validate_confirm_message')"
          @click="validateComplete()" />
        <AkButtonAction
          v-if="this.isGas() && this.isResolutionComplete"
          :label="$t('litige.refuse')"
          little
          danger
          @click="openRefusalSidebar()" />

        <!-- Gagné / Perdu -->
        <AkButtonAction
          v-if="this.isStatusEnCours && this.isResolutionValidated"
          :label="$t('litige.gagne')"
          success
          @click="openWonSidebar()" />

        <AkButtonAction
          v-if="this.isStatusEnCours && this.isResolutionValidated"
          :label="$t('litige.perdu')"
          little
          danger
          @click="openLostSidebar()" />

        <AkButtonAction
          v-if="this.litige.id && !this.isStatusATraiter && !this.isStatusResolu"
          :label="$t('update')"
          class="btn-primary-gradient xs"
          @click="toggleUpdateSidebar()">
        </AkButtonAction>
      </div>
    </template>

    <template #content>
      <GaContentView>
        <template #content>
          <!-- Information sur l'état du dossier -->
          <span
            v-if="this.isGav() && this.isStatusEnCours && this.isResolutionComplete"
            class="inline-block w-full p-3 bg-[#b8e0f1] text-white rounded-lg mb-4">
            {{ this.$t('litige.waiting_gas_validation') }}
          </span>
          <!-- Litige -->
          <div class="p-2">
            <span class="title-border mb-4">Informations du litige</span>

            <div class="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
              <!-- Statut / Résolution -->
              <div class="flex flex-col gap-1" v-if="this.isStatusATraiter">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('litige.status') }}</span>
                <span class="font-inter font-medium text-gray text-md">{{
                  this.displayForLitigeStatusConst(litige.status)
                }}</span>
              </div>
              <div class="flex flex-col gap-1" v-else>
                <span class="font-inter font-semibold text-dark text-base">{{ $t('litige.status') }}</span>
                <span class="font-inter font-medium text-gray text-md">{{
                  this.displayForLitigeStatusConst(litige.resolution)
                }}</span>
              </div>

              <div class="flex flex-col gap-1">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('reservation.one') }}</span>
                <span class="font-inter font-medium text-gray text-md">
                  <router-link
                    class="text-primary hover:text-primary font-semibold"
                    :to="{
                      name: 'reservationPage',
                      params: {reservationId: litige.reservationId},
                    }">
                    {{ litige.localizer }}
                  </router-link>
                </span>
              </div>
            </div>
          </div>

          <div class="p-2">
            <div class="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
              <div class="flex flex-col gap-1" v-if="this.isGas()">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('company.one') }}</span>
                <span class="font-inter font-medium text-gray text-md">{{ litige.companyName }}</span>
              </div>
              <div class="flex flex-col gap-1">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('accommodation.short') }}</span>
                <span class="font-inter font-medium text-gray text-md">
                  <router-link
                    class="text-primary hover:text-primary font-semibold"
                    :to="{
                      name: 'accommodationPage',
                      params: {accommodationId: litige.accommodationId},
                    }">
                    {{ litige.accommodationName }}
                  </router-link>
                </span>
              </div>
              <div class="flex flex-col gap-1" v-if="!this.isOwner()">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('owner.ownerDisplay') }}</span>
                <span class="font-inter font-medium text-gray text-md">
                  <router-link
                    class="text-primary hover:text-primary font-semibold"
                    :to="{
                      name: 'ownerPage',
                      params: {ownerId: litige.ownerId},
                    }">
                    {{ litige.ownerDisplay }}
                  </router-link>
                </span>
              </div>
            </div>
          </div>

          <!-- Dossier litige -->

          <div class="p-2" v-if="!this.isStatusATraiter">
            <div class="grid md:grid-cols-3 sm:grid-cols-2 gap-4">
              <!-- Revue du dossier -->
              <div class="flex flex-col gap-1">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('litige.complete_date') }}</span>
                <span class="font-inter font-medium text-gray text-md">{{ this.formatDateIfNeeded(litige.date) }}</span>
              </div>

              <!-- Total estimé -->
              <div class="flex flex-col gap-1">
                <span class="font-inter font-semibold text-dark text-base">{{
                  $t('litige.total_amount_estimated_ttc')
                }}</span>
                <span class="font-inter font-medium text-gray text-md">{{
                  this.formatCurrencyEUR(litige.amountEstimatedTtc)
                }}</span>
              </div>
            </div>
          </div>

          <div class="p-2" v-if="this.isResolutionRefused || this.isStatusResolu">
            <div class="grid md:grid-cols-3 sm:grid-cols-2 gap-4">
              <!-- Commentaire de refus -->
              <div class="flex flex-col gap-1 col-span-3" v-if="this.isResolutionRefused">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('litige.refused_comment') }}</span>
                <span class="font-inter font-medium text-gray text-md">{{ litige.refusalComment }}</span>
              </div>

              <!-- Résolution du dossier -->
              <div class="flex flex-col gap-1" v-if="this.isStatusResolu">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('litige.date_decision') }}</span>
                <span class="font-inter font-medium text-gray text-md">{{
                  this.formatDateIfNeeded(litige.dateDecision)
                }}</span>
              </div>

              <!-- Réellement encaissé -->
              <div class="flex flex-col gap-1" v-if="this.isResolutionGagne">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('litige.amount_ttc') }}</span>
                <span class="font-inter font-medium text-gray text-md">{{
                  this.formatCurrencyEUR(litige.amountTtc)
                }}</span>
              </div>

              <!-- Raison de la perte -->
              <div class="flex flex-col gap-1 col-span-3" v-if="this.isResolutionPerdu">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('litige.reason') }}</span>
                <span class="font-inter font-medium text-gray text-md">{{ litige.reason }}</span>
              </div>
            </div>
          </div>

          <div class="p-2" v-if="this.isResolutionGagne">
            <div class="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
              <div class="flex flex-col gap-1">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('litige.amount_gas') }}</span>
                <span class="font-inter font-medium text-gray text-md">{{
                  this.formatCurrencyEUR(litige.amountGas)
                }}</span>
              </div>
              <div class="flex flex-col gap-1">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('litige.amount_gav') }}</span>
                <span class="font-inter font-medium text-gray text-md">{{
                  this.formatCurrencyEUR(litige.amountGav)
                }}</span>
              </div>
              <div class="flex flex-col gap-1">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('litige.amount_owner') }}</span>
                <span class="font-inter font-medium text-gray text-md">{{
                  this.formatCurrencyEUR(litige.amountOwner)
                }}</span>
              </div>
            </div>
          </div>

          <!-- Litige items (Articles) -->

          <template v-if="this.hasLitigeItem">
            <LitigeItemList
              v-model="litige.litigeItemList"
              :displayFiles="!this.isStatusATraiter"
              displayMaintenanceFiles
              displayMaintenanceDate
              :canDeleteFiles="false"
              :canUploadFiles="false"
              disabled />
          </template>
        </template>
      </GaContentView>
    </template>
    <template #extra>
      <!-- SIDEBAR -->
      <LitigeUpdateSidebar ref="litigeUpdateSidebar" @litige-updated="this.litigeUpdated()" />
      <LitigeProcessSidebar ref="litigeProcessSidebar" @litige-updated="this.litigeUpdated()" />
      <LitigeRefusalSidebar ref="litigeRefusalSidebar" @litige-updated="this.litigeUpdated()" />
      <LitigeWonSidebar ref="litigeWonSidebar" @litige-updated="this.litigeUpdated()" />
      <LitigeLostSidebar ref="litigeLostSidebar" @litige-updated="this.litigeUpdated()" />
    </template>
  </GaView>
</template>
