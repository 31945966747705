<script>
import {getColorIconFromMIME, getFontAwesomeIconFromMIME} from '@utils/FontAwesomeMimeType';
import prettyBytes from 'pretty-bytes';

/* MIXINS */
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import ficService from '@services/ficService';

export default {
  mixins: [messageMixin],
  props: {
    modelValue: {
      required: true,
    },
    ficPreview: {
      type: Boolean,
      required: false,
      default: false,
    },
    canDelete: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['update:modelValue', 'download', 'delete'],
  data() {
    return {
      fileAction: null,
      base64: undefined,
      displayDeleteDialog: false,
    };
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  mounted() {
    ficService
      .preview(this.currentValue)
      .then((data) => {
        this.base64 = data;
      })
      .catch((e) => {
        // Catch l'erreur pour ne pas l'afficher côté IHM
      });
  },
  methods: {
    getFileIcon() {
      return getFontAwesomeIconFromMIME(this.currentValue.fileName);
    },
    getColorIcon() {
      return getColorIconFromMIME(this.currentValue.fileName);
    },
    getSizeReadable() {
      return prettyBytes(this.currentValue.size);
    },
    downloadFile(isPreview) {
      this.$emit('download', this.currentValue, isPreview);
    },
    confirmDeleteFile() {
      this.$emit('delete', this.currentValue);
    },
  },
};
</script>

<template>
  <div class="flex border rounded-lg border-lightGray hover:border-primary p-2">
    <div class="flex gap-1 items-center w-full">
      <div class="flex gap-2 items-center w-full cursor-pointer" @click="downloadFile(true)">
        <img v-if="ficPreview && base64" :src="base64" class="w-[32px] h-[32px] object-cover object-center rounded" />
        <i :class="[getFileIcon(), getColorIcon()]" v-if="!ficPreview || !base64" class="fa" />
        <div class="flex-1 flex flex-col gap-1">
          <span class="text-dark line-clamp-1 break-all" :title="currentValue.fileName">{{
            currentValue.fileName
          }}</span>
          <span class="text-gray text-sm">{{ getSizeReadable() }}</span>
        </div>
      </div>
      <div class="flex gap-1 items-center">
        <i class="ga-icon ga-download text-primary text-2xl cursor-pointer" @click="downloadFile(false)" />
        <i v-if="canDelete" class="ga-icon ga-trash text-redGa text-2xl cursor-pointer" @click="confirmDeleteFile" />
      </div>
    </div>
  </div>
</template>
