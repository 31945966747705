<script>
import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

/* COMPOSANTS */
import {Icon} from '@iconify/vue';
import AkInputText from '@components/v2/input/AkInputText';
import AkInputTextArea from '@components/v2/input/AkInputTextArea.vue';
import AkDialog from '@components/general/AkDialog';
import GaView from '@components/v2/layout/GaView';
import GaListView from '@components/v2/layout/GaListView';
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination.vue';
import AkButtonAction from '@components/v2/general/AkButtonAction.vue';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import cancelRuleService from '@services/cancelRuleService';

export default {
  components: {Icon, AkButtonAction, AkDatatablePagination, AkInputTextArea, GaView, GaListView, AkInputText, AkDialog},
  mixins: [randomRef, utilsMixin, messageMixin],
  setup() {
    return {v$: useVuelidate()};
  },
  metaInfo() {
    return {
      title: 'settings.cancel_rule',
    };
  },
  data() {
    return {
      loading: true,
      list: [],
      item: {
        label: '',
        description: '',
      },
    };
  },
  validations() {
    return {
      item: {
        label: {required},
        description: {required},
      },
    };
  },
  mounted() {
    cancelRuleService.cancelRules({}).then((data) => {
      this.list = data;
      this.loading = false;
    });
  },
  methods: {
    create() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.$refs.dialogCreate.init();
        return;
      }

      this.loading = true;
      cancelRuleService
        .create(this.item)
        .then((data) => {
          this.success(this.$t('settings.cancel_rule_added'));
          this.list.push(data);
        })
        .finally(() => {
          this.loading = false;
          this.$refs.dialogCreate.hide();
        });
    },
    update() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.$refs.dialogEdit.init();
        return;
      }

      this.loading = true;
      cancelRuleService
        .update(this.item)
        .then((data) => {
          this.replaceInListById(this.list, data);
          this.success(this.$t('settings.cancel_rule_updated'));
        })
        .finally(() => {
          this.loading = false;
          this.$refs.dialogEdit.hide();
        });
    },
    delete() {
      cancelRuleService
        .delete(this.item)
        .then((data) => {
          this.removeInListById(this.list, data.id);
          this.success(this.item.label + ' a bien été supprimé');
        })
        .catch((e) => {
          let content = 'Une erreur est survenue durant la suppression';
          if (e.response) {
            content = 'Une erreur est survenue durant la suppression : ' + e.response.data.error;
            this.addError(content);
          } else {
            this.addError(content);
          }
        });
    },

    openCreateDialog() {
      this.item = {
        label: '',
        description: '',
      };
      this.$refs.dialogCreate.show();
    },
    openEditDialog(item) {
      this.item = {...item};
      this.$refs.dialogEdit.show();
    },
    openDeleteDialog(item) {
      this.item = item;
      this.$refs.dialogDelete.show();
    },
  },
};
</script>

<template>
  <GaView :ref="ref" :title="$t('settings.cancel_rule')">
    <template #action>
      <div class="flex justify-end">
        <AkButtonAction little :label="$t('add')" @click="openCreateDialog()" />
      </div>
    </template>

    <template #content>
      <GaListView>
        <template #content>
          <AkDatatablePagination :lazy="false" :loading="loading" :value="list">
            <template #empty>
              {{ $t('settings.cancel_rule_empty') }}
            </template>
            <Column :header="$t('item_label.label')" field="label">
              <template #body="slotProps">
                <span>{{ slotProps.data.label }}</span>
              </template>
            </Column>
            <Column :header="$t('settings.description')" field="description">
              <template #body="slotProps">
                <span>{{ slotProps.data.description }}</span>
              </template>
            </Column>
            <Column style="width: 100px">
              <template #body="slotProps">
                <div class="flex justify-end gap-2">
                  <span class="hover:cursor-pointer" @click="openEditDialog(slotProps.data)">
                    <i class="ga-icon ga-edit text-2xl text-primary" />
                  </span>
                  <span class="hover:cursor-pointer" @click="openDeleteDialog(slotProps.data)">
                    <i class="ga-icon ga-trash text-2xl text-redGa" />
                  </span>
                </div>
              </template>
            </Column>
          </AkDatatablePagination>
        </template>
      </GaListView>
    </template>

    <template #extra>
      <AkDialog
        ref="dialogCreate"
        :title="$t('settings.cancel_rule')"
        :validate-label="$t('add')"
        width="450px"
        @validate="create()">
        <div class="flex flex-col gap-2">
          <AkInputText v-model="item.label" :label="$t('item_label.label')" :validator="v$.item.label" />
          <AkInputTextArea
            v-model="item.description"
            :label="$t('settings.description')"
            :validator="v$.item.description" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogEdit"
        :title="$t('settings.cancel_rule')"
        :validate-label="$t('update')"
        width="450px"
        @validate="update()">
        <div class="flex flex-col gap-2">
          <AkInputText v-model="item.label" :label="$t('item_label.label')" :validator="v$.item.label" />
          <AkInputTextArea
            v-model="item.description"
            :label="$t('settings.description')"
            :validator="v$.item.description" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogDelete"
        :auto-hide-on-validate="true"
        :cancel-label="$t('no')"
        :title="$t('delete_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.delete()">
        <div class="flex items-center gap-2">
          <Icon icon="fluent:warning-20-regular" class="text-redGa" width="26" />
          <span class="text-dark font-normal">{{ $t('settings.confirm_delete_cancel_rule') }}</span>
        </div>
      </AkDialog>
    </template>
  </GaView>
</template>
