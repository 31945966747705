<script>
import {required} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

/* COMPOSANTS */
import AkDialog from '@components/general/AkDialog';
import AkInputText from '@components/v2/input/AkInputText';
import GaView from '@components/v2/layout/GaView';
import GaListView from '@components/v2/layout/GaListView.vue';
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination.vue';
import AkButtonAction from '@components/v2/general/AkButtonAction.vue';
import AkMultiSelect from '@components/v2/input/AkMultiSelect.vue';
import AkDropdown from '@components/v2/input/AkDropdown';
import AkChips from '@components/input/AkChips';
import {Icon} from '@iconify/vue';
import TaskSidebar from '@views/task/sidebars/TaskSidebar.vue';
import TaskgroupSidebar from '@views/task/sidebars/TaskgroupSidebar.vue';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import {FilterMatchMode} from 'primevue/api';
import taskTypeConst from '@mixins/const/taskTypeConst';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import taskService from '@services/taskService';
import taskgroupService from '@services/taskgroupService';

export default {
  components: {
    Icon,
    GaView,
    GaListView,
    AkDatatablePagination,
    AkButtonAction,
    AkDialog,
    AkChips,
    AkDropdown,
    AkInputText,
    AkMultiSelect,
    TaskSidebar,
    TaskgroupSidebar,
  },
  mixins: [randomRef, utilsMixin, messageMixin, taskTypeConst],
  setup() {
    return {v$: useVuelidate()};
  },
  metaInfo() {
    return {
      title: 'settings.tasks',
    };
  },
  data() {
    return {
      filter: undefined,
      filterGroup: undefined,
      currentTab: 'TASK',
      tasks: [],
      taskgroups: [],
      fullTasks: [],
      fullTaskgroups: [],
      task: {
        typeData: {},
      },
      taskgroup: {},
      sidebarTaskId: null,
      sidebarTaskgroupId: null,

      loading: true,
      loadingGroup: true,
    };
  },
  validations() {
    return {
      task: {
        label: {required},
        type: {required},
      },
      taskgroup: {
        label: {required},
      },
    };
  },
  created() {
    this.initFilters();
    this.initFiltersGroup();
  },
  mounted() {
    this.refresh();
  },
  methods: {
    changeTab(tab) {
      this.currentTab = tab;
      this.refresh();
    },
    refresh(force = false) {
      switch (this.currentTab) {
        case 'TASK':
          if (!this.fullTasks || this.fullTasks.length === 0 || force) {
            this.loading = true;
            taskService.tasks().then((data) => {
              this.fullTasks = data;
              this.tasks = data;
              this.loading = false;
            });
          }
          break;
        case 'TASKGROUP':
          if (!this.fullTaskgroups || this.fullTaskgroups.length === 0 || force) {
            this.loadingGroup = true;
            taskgroupService.taskgroups().then((data) => {
              this.fullTaskgroups = data;
              this.taskgroups = data;
              this.loadingGroup = false;
            });
          }
          break;
      }
    },
    createTask() {
      this.v$.task.$touch();
      if (this.v$.task.$error) {
        this.$refs.dialogCreateTask.init();
        return;
      }
      this.loading = true;
      taskService.create(this.task).then(() => {
        this.refresh(true);
        this.task = {};
        this.$refs.dialogCreateTask.hide();
        this.loading = false;
        this.success(this.$t('task.added'));
      });
    },
    createTaskgroup() {
      this.v$.taskgroup.$touch();
      if (this.v$.taskgroup.$error) {
        this.$refs.dialogCreateTaskgroup.init();
        return;
      }
      this.loadingGroup = true;
      taskgroupService.create(this.taskgroup).then(() => {
        this.refresh(true);
        this.taskgroup = {};
        this.$refs.dialogCreateTaskgroup.hide();
        this.loadingGroup = false;
        this.success(this.$t('taskgroup.added'));
      });
    },
    updateTask() {
      this.v$.task.$touch();
      if (this.v$.task.$error) {
        this.$refs.dialogEditTask.init();
        return;
      }
      this.loading = true;
      taskService.update(this.task).then((data) => {
        this.refresh(true);
        this.task = {};
        this.$refs.dialogEditTask.hide();
        this.loading = false;
        this.success(this.$t('task.updated'));
      });
    },
    updateTaskgroup() {
      this.v$.taskgroup.$touch();
      if (this.v$.taskgroup.$error) {
        this.$refs.dialogEditTaskgroup.init();
        return;
      }
      this.loadingGroup = true;
      taskgroupService.update(this.taskgroup).then(() => {
        this.refresh(true);
        this.taskgroup = {};
        this.$refs.dialogEditTaskgroup.hide();
        this.loadingGroup = false;
        this.success(this.$t('taskgroup.updated'));
      });
    },
    deleteTask() {
      this.loading = true;
      taskService
        .delete(this.task)
        .then(() => {
          this.refresh(true);
          this.loading = false;
          this.$refs.dialogDeleteTask.hide();
          this.success('La tâche a bien été supprimée');
        })
        .catch((e) => {
          if (e.response.status === 406) {
            this.addError('La tâche est liée à un modèle de cahier des charges, et ne peut être supprimée');
          } else {
            this.addError(this.$t('unknow_error'));
          }
          this.loading = false;
          this.$refs.dialogDeleteTask.hide();
        });
    },
    deleteTaskgroup() {
      this.loading = true;
      taskgroupService
        .delete(this.taskgroup)
        .then(() => {
          this.refresh(true);
          this.loading = false;
          this.$refs.dialogDeleteTaskgroup.hide();
          this.success('Le groupe de tâche a bien été supprimé');
        })
        .catch((e) => {
          if (e.response.status === 406) {
            this.addError('Le groupe de tâche est lié à un modèle de cahier des charges, et ne peut être supprimé');
          } else {
            this.addError(this.$t('unknow_error'));
          }
          this.loading = false;
          this.$refs.dialogDeleteTaskgroup.hide();
        });
    },
    openCreateTaskDialog() {
      this.task = {
        typeData: {},
      };
      this.$refs.dialogCreateTask.show();
    },
    openCreateTaskgroupDialog() {
      this.taskgroup = {};
      this.$refs.dialogCreateTaskgroup.show();
    },
    openEditTaskDialog(task) {
      this.task = {...task};
      this.$refs.dialogEditTask.show();
    },
    openEditTaskgroupDialog(taskgroup) {
      this.taskgroup = {...taskgroup};
      this.$refs.dialogEditTaskgroup.show();
    },
    openDeleteTaskDialog(task) {
      this.task = task;
      this.$refs.dialogDeleteTask.show();
    },
    openDeleteTaskgroupDialog(taskgroup) {
      this.taskgroup = taskgroup;
      this.$refs.dialogDeleteTaskgroup.show();
    },
    clearFilter() {
      this.initFilters();
    },
    initFilters() {
      this.filter = {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
      };
    },
    clearFilterGroup() {
      this.initFiltersGroup();
    },
    initFiltersGroup() {
      this.filterGroup = {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
      };
    },
    filterTasks(e) {
      switch (this.currentTab) {
        case 'TASK':
          this.tasks = this.fullTasks.filter((task) => task.label.toLowerCase().includes(e.toLowerCase()));
          break;
        case 'TASKGROUP':
          this.taskgroups = this.fullTaskgroups.filter((task) => task.label.toLowerCase().includes(e.toLowerCase()));
          break;
      }
    },
    openTaskDetailSidebar({data}) {
      this.sidebarTaskId = data.id;
      this.$refs.taskSidebar.show();
    },
    openTaskGroupDetailSidebar({data}) {
      this.sidebarTaskgroupId = data.id;
      this.$refs.taskgroupSidebar.show();
    },
  },
  computed: {
    isRating() {
      return this.task.type === 'RATING';
    },
    hasOptions() {
      return this.task.type === 'SIMPLE_OPTION' || this.task.type === 'MULTI_OPTION';
    },
  },
};
</script>

<template>
  <GaView :ref="ref" :title="$t('settings.tasks')">
    <template #action>
      <AkButtonAction
        :label="currentTab === 'TASK' ? $t('task.add') : $t('taskgroup.add')"
        little
        @click="currentTab === 'TASK' ? openCreateTaskDialog() : openCreateTaskgroupDialog()" />
    </template>
    <template #content>
      <GaListView searchable @search="filterTasks">
        <template #tabs>
          <div class="flex items-center">
            <span :class="[{active: currentTab === 'TASK'}]" class="list-table-item" @click="changeTab('TASK')">{{
              $t('settings.tasks')
            }}</span>
            <span
              :class="[{active: currentTab === 'TASKGROUP'}]"
              class="list-table-item"
              @click="changeTab('TASKGROUP')"
              >{{ $t('settings.taskgroups') }}</span
            >
          </div>
        </template>
        <template #content>
          <AkDatatablePagination
            v-show="currentTab === 'TASK'"
            :lazy="false"
            :loading="loading"
            :value="tasks"
            clickable
            @row-click="openTaskDetailSidebar">
            <template #empty>
              {{ $t('task.empty') }}
            </template>
            <Column :header="$t('task.item')" field="label" />
            <Column :header="$t('task.type')" field="type">
              <template #body="slotProps">
                {{ displayForTaskTypeConst(slotProps.data.type) }}
              </template>
            </Column>
            <Column :header="$t('task.groupsNb')">
              <template #body="slotProps">
                {{ slotProps.data.groupIds ? slotProps.data.groupIds.length : 0 }}
              </template>
            </Column>
            <Column style="width: 100px">
              <template #body="slotProps">
                <div class="flex justify-end gap-2">
                  <span class="hover:cursor-pointer" @click.stop="openEditTaskDialog(slotProps.data)">
                    <i class="ga-icon ga-edit text-2xl text-primary" />
                  </span>
                  <span class="hover:cursor-pointer" @click.stop="openDeleteTaskDialog(slotProps.data)">
                    <i class="ga-icon ga-trash text-2xl text-redGa" />
                  </span>
                </div>
              </template>
            </Column>
          </AkDatatablePagination>
          <AkDatatablePagination
            v-show="currentTab === 'TASKGROUP'"
            :lazy="false"
            :loading="loadingGroup"
            :value="taskgroups"
            clickable
            @row-click="openTaskGroupDetailSidebar">
            <template #empty>
              {{ $t('taskgroup.empty') }}
            </template>
            <Column :header="$t('taskgroup.item')" field="label" />
            <Column :header="$t('taskgroup.tasksNb')">
              <template #body="slotProps">
                {{ slotProps.data.taskIds ? slotProps.data.taskIds.length : 0 }}
              </template>
            </Column>
            <Column style="width: 100px">
              <template #body="slotProps">
                <div class="flex justify-end gap-2">
                  <span class="hover:cursor-pointer" @click.stop="openEditTaskgroupDialog(slotProps.data)">
                    <i class="ga-icon ga-edit text-2xl text-primary" />
                  </span>
                  <span class="hover:cursor-pointer" @click.stop="openDeleteTaskgroupDialog(slotProps.data)">
                    <i class="ga-icon ga-trash text-2xl text-redGa" />
                  </span>
                </div>
              </template>
            </Column>
          </AkDatatablePagination>
        </template>
      </GaListView>
    </template>

    <template #extra>
      <AkDialog
        ref="dialogCreateTask"
        :cancel-label="$t('cancel')"
        :title="$t('task.add')"
        :validate-label="$t('add')"
        width="450px"
        @validate="this.createTask()">
        <div class="flex flex-col gap-2">
          <AkInputText v-model="task.label" :label="$t('task.label')" :validator="v$.task.label" />
          <AkDropdown
            v-model="task.type"
            :label="$t('task.type')"
            :options="taskTypeConst"
            :validator="v$.task.type"
            option-label="label"
            option-value="value" />
          <AkInputText v-if="isRating" v-model="task.typeData.rating" label="Echelle (consigne)" />
          <AkChips v-if="hasOptions" v-model="task.typeData.options" label="Choix" />
          <AkMultiSelect
            v-if="taskgroups.length > 0"
            v-model="task.groupIds"
            :filter="true"
            :label="$t('task.groups')"
            :options="taskgroups"
            :placeholder="$t('task.selectGroups')"
            display="chip"
            option-label="label"
            option-value="id" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogCreateTaskgroup"
        :cancel-label="$t('cancel')"
        :title="$t('task.add')"
        :validate-label="$t('add')"
        width="450px"
        @validate="this.createTaskgroup()">
        <div class="flex flex-col gap-2">
          <AkInputText v-model="taskgroup.label" :label="$t('taskgroup.label')" :validator="v$.taskgroup.label" />
          <AkMultiSelect
            v-if="tasks.length > 0"
            v-model="taskgroup.taskIds"
            :filter="true"
            :label="$t('taskgroup.tasks')"
            :options="tasks"
            :placeholder="$t('taskgroup.selectTasks')"
            display="chip"
            option-label="label"
            option-value="id" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogEditTask"
        :cancel-label="$t('cancel')"
        :title="$t('task.update')"
        :validate-label="$t('update')"
        width="450px"
        @validate="this.updateTask()">
        <div class="flex flex-col gap-2">
          <AkInputText v-model="task.label" :label="$t('task.label')" :validator="v$.task.label" class-name="col-12" />
          <AkDropdown
            v-model="task.type"
            :label="$t('task.type')"
            :options="taskTypeConst"
            :validator="v$.task.type"
            class-name="col-12"
            option-label="label"
            option-value="value" />
          <AkInputText v-if="isRating" v-model="task.typeData.rating" class-name="col-12" label="Echelle (consigne)" />
          <AkChips v-if="hasOptions" v-model="task.typeData.options" class-name="col-12" label="Choix" />
          <AkMultiSelect
            v-if="taskgroups.length > 0"
            v-model="task.groupIds"
            :filter="true"
            :label="$t('task.groups')"
            :options="taskgroups"
            :placeholder="$t('task.selectGroups')"
            display="chip"
            option-label="label"
            option-value="id" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogEditTaskgroup"
        :cancel-label="$t('cancel')"
        :title="$t('taskgroup.update')"
        :validate-label="$t('update')"
        width="450px"
        @validate="this.updateTaskgroup()">
        <div class="flex flex-col gap-2">
          <AkInputText
            v-model="taskgroup.label"
            :label="$t('taskgroup.label')"
            :validator="v$.taskgroup.label"
            class-name="col-12" />
          <AkMultiSelect
            v-if="tasks.length > 0"
            v-model="taskgroup.taskIds"
            :filter="true"
            :label="$t('taskgroup.tasks')"
            :options="tasks"
            :placeholder="$t('taskgroup.selectTasks')"
            display="chip"
            option-label="label"
            option-value="id" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogDeleteTask"
        :cancel-label="$t('no')"
        :title="$t('delete_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.deleteTask()">
        <div class="flex items-center gap-2">
          <Icon class="text-redGa" icon="fluent:warning-20-regular" width="26" />
          <span v-if="task">{{ $t('task.confirm_delete') }}</span>
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogDeleteTaskgroup"
        :cancel-label="$t('no')"
        :title="$t('delete_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.deleteTaskgroup()">
        <div class="flex items-center gap-2">
          <Icon class="text-redGa" icon="fluent:warning-20-regular" width="26" />
          <span v-if="taskgroup">{{ $t('taskgroup.confirm_delete') }}</span>
        </div>
      </AkDialog>

      <TaskSidebar ref="taskSidebar" :taskId="sidebarTaskId" @openEditDialog="openEditTaskDialog" />
      <TaskgroupSidebar
        ref="taskgroupSidebar"
        :taskgroupId="sidebarTaskgroupId"
        @openEditDialog="openEditTaskgroupDialog" />
    </template>
  </GaView>
</template>
