<script>
/* COMPOSANTS */

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import documentModelTypeConst from '@mixins/const/documentModelTypeConst';

/* SERVICES */
import documentModelService from '@services/documentModelService';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';
import GaView from '@components/v2/layout/GaView';
import GaListView from '@components/v2/layout/GaListView.vue';
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination.vue';
import DocumentModelCreate from '@views/documentmodel/DocumentModelCreate.vue';
import DocumentModelUpdate from '@views/documentmodel/DocumentModelUpdate.vue';
import AkButtonValidation from '@components/v2/general/AkButtonValidation.vue';
import {Icon} from '@iconify/vue';

export default {
  components: {
    Icon,
    AkButtonValidation,
    DocumentModelUpdate,
    DocumentModelCreate,
    AkDatatablePagination,
    GaListView,
    GaView,
  },
  mixins: [randomRef, loaderMixin, messageMixin, utilsMixin, documentModelTypeConst],
  metaInfo() {
    return {
      title: 'settings.document_model',
    };
  },
  data() {
    return {
      list: [],
      filter: undefined,
      sidebarCreateVisible: false,
      sidebarUpdateVisible: false,
      searchValue: undefined,
      item: {},
      loading: true,
      submitted: false,
      documentModelId: undefined,
      displayCreateDialog: false,
      displayEditDialog: false,
      displayDeleteDialog: false,
    };
  },
  computed: {
    listFilter() {
      if (this.list) {
        if (this.filter) {
          return this.list.filter(
            (i) => i.type === this.filter && (this.searchValue === undefined || i.name.includes(this.searchValue)),
          );
        } else return this.list.filter((i) => this.searchValue === undefined || i.name.includes(this.searchValue));
      }
      return [];
    },
    sidebarVisible() {
      return this.sidebarUpdateVisible || this.sidebarCreateVisible;
    },
    sidebarTitle() {
      if (this.sidebarCreateVisible) return this.$t('document_model.add');
      else if (this.sidebarUpdateVisible) {
        return this.$t('document_model.update');
      }
      return '';
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    hideSidebar() {
      this.sidebarCreateVisible = false;
      this.sidebarUpdateVisible = false;
    },
    refresh() {
      this.loading = true;
      documentModelService.list().then((data) => {
        this.list = data;
        this.loading = false;
      });
    },
    selectFilter(filter) {
      this.filter = filter;
    },
    search(value) {
      this.searchValue = value;
    },
    toggleCreateSidebar() {
      this.sidebarCreateVisible = !this.sidebarCreateVisible;
      if (this.sidebarCreateVisible === false) {
        this.refresh();
      }
    },
    toggleUpdateSidebar() {
      this.sidebarUpdateVisible = !this.sidebarUpdateVisible;
      if (this.sidebarUpdateVisible === false) {
        this.refresh();
      }
    },
    delete() {
      documentModelService
        .delete(this.item)
        .then((item) => {
          this.list = this.list.filter((val) => val.id !== item.id);
          this.success(this.item.name + ' a bien été supprimé');
          this.displayDeleteDialog = false;
        })
        .catch((e) => {
          this.addError(e.response.data.error);
          this.displayDeleteDialog = false;
        })
        .finally(() => {});
    },
    openUpdateSidebar(item) {
      this.documentModelId = item.id;
      this.sidebarUpdateVisible = true;
    },
    openDeleteDialog(item) {
      this.item = item;
      this.displayDeleteDialog = true;
    },
    closeDeleteDialog() {
      this.displayDeleteDialog = false;
    },
    downloadFic(data) {
      this.showTotalLoader();
      documentModelService
        .documentModelDownload(data.id)
        .catch(() => {
          this.addError('Une erreur est survenue lors du téléchargement');
        })
        .finally(() => {
          this.hideLoader();
        });
    },
  },
};
</script>

<template>
  <GaView :title="$t('settings.document_model')">
    <template #action>
      <div class="flex justify-end">
        <span @click="toggleCreateSidebar" class="btn-primary-gradient xs">
          {{ $t('document_model.add') }}
        </span>
      </div>
    </template>

    <template #content>
      <GaListView searchable @search="search">
        <template #tabs>
          <div class="flex items-center">
            <span
              class="list-table-item"
              @click="selectFilter(undefined)"
              :class="[{active: this.filter === undefined}]"
              >Tous</span
            >
            <span class="list-table-item" @click="selectFilter('RH')" :class="[{active: this.filter === 'RH'}]"
              >RH</span
            >
            <span
              class="list-table-item"
              @click="selectFilter('COMMERCIAL')"
              :class="[{active: this.filter === 'COMMERCIAL'}]"
              >Commercial</span
            >
            <span
              class="list-table-item"
              @click="selectFilter('PRESTATION')"
              :class="[{active: this.filter === 'PRESTATION'}]"
              >Prestation</span
            >
          </div>
        </template>
        <template #content>
          <AkDatatablePagination :loading="loading" :value="listFilter" :lazy="false">
            <template #empty>
              {{ $t('document_model.empty') }}
            </template>
            <Column :header="$t('document_model.name')" style="min-width: 300px">
              <template #body="slotProps">
                {{ slotProps.data.name }}
              </template>
            </Column>
            <Column :header="$t('document_model.type')" style="width: 200px">
              <template #body="slotProps">
                {{ displayTypeForDocumentModelTypeConst(slotProps.data.type) }}
              </template>
            </Column>
            <Column :header="$t('document_model.subtype')" style="width: 200px">
              <template #body="slotProps">
                {{ displaySubTypeForDocumentModelTypeConst(slotProps.data.type, slotProps.data.subType) }}
              </template>
            </Column>

            <Column body-style="text-align: right; overflow: visible" style="width: 220px">
              <template #body="slotProps">
                <div class="flex justify-end gap-2">
                  <span v-if="slotProps.data.fic?.id" @click="downloadFic(slotProps.data)">
                    <i class="ga-icon ga-download text-2xl text-primary hover:cursor-pointer"
                  /></span>
                  <span @click="openUpdateSidebar(slotProps.data)">
                    <i class="ga-icon ga-edit text-2xl text-primary hover:cursor-pointer" />
                  </span>
                  <span @click="openDeleteDialog(slotProps.data)">
                    <i class="ga-icon ga-trash text-2xl text-redGa hover:cursor-pointer"
                  /></span>
                </div>
              </template>
            </Column>
          </AkDatatablePagination>
        </template>
      </GaListView>
    </template>
    <template #extra>
      <PDialog
        v-model:visible="displayDeleteDialog"
        :header="$t('document_model.delete')"
        :modal="true"
        @hide="displayDeleteDialog = false">
        <div class="flex items-center gap-2">
          <Icon icon="fluent:warning-20-regular" class="text-redGa" width="26" />
          <span class="text-dark font-normal">{{ $t('document_model.confirm_delete') }}</span>
        </div>
        <template #footer>
          <div class="flex w-full justify-center items-center gap-2">
            <span class="btn-light-gray xs min-w-20" @click="closeDeleteDialog">Non</span>
            <AkButtonValidation
              btn-class="btn-primary-gradient xs min-w-20"
              ref="btnDelete"
              :label="$t('yes')"
              @action="this.delete()" />
          </div>
        </template>
      </PDialog>

      <Sidebar :visible="sidebarVisible" block-scroll :baseZIndex="9998" position="right">
        <template #container>
          <div class="p-sidebar-header">
            <div class="p-sidebar-header-content">{{ sidebarTitle }}</div>
            <button autofocus="" type="button" class="p-sidebar-close p-sidebar-icon p-link" @click="hideSidebar">
              <span
                class="p-sidebar-close-icon ga-icon ga-close_circle text-4xl text-gray hover:text-primary hover:bg-transparent"></span>
            </button>
          </div>
          <div class="p-sidebar-content">
            <perfect-scrollbar class="h-full">
              <DocumentModelCreate v-if="sidebarCreateVisible" @create-done="toggleCreateSidebar" />
              <DocumentModelUpdate
                :documentModelId="this.documentModelId"
                v-if="sidebarUpdateVisible"
                @create-done="toggleUpdateSidebar" />
            </perfect-scrollbar>
          </div>
        </template>
      </Sidebar>
    </template>
  </GaView>
</template>
