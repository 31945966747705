<script>
/* COMPOSANTS */

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import maintenanceStatusConst from '@mixins/const/maintenanceStatusConst';
import maintenancePriorityConst from '@mixins/const/maintenancePriorityConst';
import maintenanceTagConst from '@mixins/const/maintenanceTagConst';
import maintenanceOwnerChoiceConst from '@mixins/const/maintenanceOwnerChoiceConst';

/* SERVICES */

export default {
  props: {
    maintenance: {
      required: true,
    },
  },
  components: {},
  mixins: [
    utilsMixin,
    permissionsMixin,
    maintenanceStatusConst,
    maintenancePriorityConst,
    maintenanceTagConst,
    maintenanceOwnerChoiceConst,
  ],
  mounted() {},
  computed: {
    priorityImg() {
      if (this.maintenance.priority === 'URGENT') return 'critical';
      return this.maintenance.priority.toLowerCase();
    },
    priority() {
      if (this.isStatusResolue) return 'priority-resolue';
      else if (this.isStatusDecisionPrise && this.isOwnerChoiceAccept) return 'priority-low';
      else if (this.isStatusAResoudre) return 'priority-medium';
      else if (this.isStatusEnAttente || this.isStatusEnCours) return 'priority-encours';
      else if (this.isStatusATraiter) return 'priority-high';
      return 'priority-critical';
    },
    priorityText() {
      if (this.isStatusDecisionPrise) return this.displayForMaintenanceOwnerChoiceConst(this.maintenance.ownerChoice);
      return this.displayForMaintenanceStatusConst(this.maintenance.status);
    },
    isTagUsure() {
      return this.maintenance.tag === 'USURE';
    },
    isTagLitige() {
      return this.maintenance.tag === 'LITIGE';
    },
    isOwnerChoiceAccept() {
      return this.maintenance.ownerChoice === 'ACCEPT';
    },
    isOwnerChoiceRefus() {
      return this.maintenance.ownerChoice === 'REFUS';
    },
    isStatusAResoudre() {
      return this.maintenance.status === 'A_RESOUDRE';
    },
    isStatusATraiter() {
      return this.maintenance.status === 'A_TRAITER';
    },
    isStatusEnAttente() {
      return this.maintenance.status === 'EN_ATTENTE';
    },
    isStatusDecisionPrise() {
      return this.maintenance.status === 'DECISION_PRISE';
    },
    isStatusEnCours() {
      return this.maintenance.status === 'EN_COURS';
    },
    isStatusResolue() {
      return this.maintenance.status === 'RESOLUE';
    },
    statusCreatedText() {
      return 'Créée il y a ' + this.fromNow(this.maintenance.dateCreated);
    },
    statusEnCoursText() {
      let txt = 'Actualisée il y a';
      if (this.isOwnerChoiceAccept) txt = 'Acceptée il y a';
      else if (this.isOwnerChoiceRefus) txt = 'Refusée il y a';
      else if (this.isStatusEnCours) txt = 'Démarrée il y a ';
      return txt + ' ' + this.fromNow(this.maintenance.dateUpdated);
    },
    statusResolueText() {
      return 'Terminée il y a ' + this.fromNow(this.maintenance.resolutionDate);
    },
  },
  methods: {},
};
</script>

<template>
  <router-link :to="{name: 'maintenanceDetails', params: {maintenanceId: maintenance.id}}" class="nohover">
    <div
      class="flex flex-col gap-2.5 p-3 rounded-lg"
      :class="[{'bg-[#F0F1F2]': this.isStatusResolue, 'bg-white': !this.isStatusResolue}]"
      :key="maintenance.id">
      <div class="flex justify-between items-center">
        <div class="flex justify-between items-center gap-4">
          <img
            v-if="!this.isStatusAResoudre"
            :src="require('@/assets/' + this.priorityImg + '.png')"
            class="h-[22px]" />
          <span class="font-sf text-base text-white font-590 priority-low px-4 py-2 rounded-3xl" :class="[priority]">
            {{ this.priorityText }}
          </span>
        </div>

        <div class="flex gap-1 items-center">
          <span
            :class="{
              'bg-lightGray': !this.isStatusResolue && !this.isStatusAResoudre,
              'bg-white': this.isStatusResolue,
            }"
            class="text-dark font-medium text-md px-4 py-2 rounded-3xl">
            {{ this.displayForMaintenanceTagConst(this.maintenance.tag) }}
          </span>
        </div>
      </div>
      <div class="flex gap-4 items-center">
        <span class="text-primary text-lg font-medium"> {{ this.maintenance.problem }} </span>
      </div>
      <div v-if="this.isGas()" class="flex gap-4 items-center">
        <span class="flex font-regular text-md text-dark capitalize font-bold">
          {{ this.maintenance.companyName }}
        </span>
      </div>
      <div class="flex gap-4 justify-between items-center">
        <span class="flex font-regular text-md text-dark capitalize" :class="{'font-bold': !this.isGas()}">{{
          this.maintenance.accommodationName
        }}</span>
        <span v-if="!this.isOwner()" class="flex font-regular text-md text-dark capitalize text-right">{{
          this.maintenance.ownerDisplay
        }}</span>
      </div>
      <span class="text-gray text-base font-regular">
        <span v-if="this.isStatusAResoudre" :title="displayDateTime(this.maintenance.dateCreated)">
          {{ this.statusCreatedText }}
        </span>
        <span v-else-if="this.isStatusResolue" :title="displayDateTime(this.maintenance.resolutionDate)">
          {{ this.statusResolueText }}
        </span>
        <span v-else :title="displayDateTime(this.maintenance.dateUpdated)">
          {{ this.statusEnCoursText }}
        </span>
      </span>
    </div>
  </router-link>
</template>
