export default {
  data() {
    return {
      reservationSiteConst: [
        {label: 'Airbnb', value: 'AIRBNB'},
        {label: 'Booking', value: 'BOOKING'},
        {label: 'Abritel', value: 'VRBO'},
        {label: 'VGA', value: 'VGA'},
        {label: 'PAP', value: 'PAP'},
        {label: 'Holidu', value: 'HOLIDU'},
        {label: 'Sans plateforme', value: 'NULL'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  computed: {
    reservationSiteConstDefined() {
      return this.reservationSiteConst.filter((el) => el.value !== undefined);
    },
    reservationSiteConstDefinedReview() {
      return this.reservationSiteConst.filter(
        (el) => el.value !== undefined && el.value !== 'PAP' && el.value !== 'HOLIDU',
      );
    },
  },
  methods: {
    displayForReservationSiteConst(value) {
      let result = this.reservationSiteConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
    iconReservation(site) {
      if (!site) return 'cancel';
      let siteLower = site.toLowerCase();

      if (siteLower.includes('vrbo')) return 'vrbo';
      if (siteLower.includes('pap')) return 'pap';
      if (siteLower.includes('holidu')) return 'holidu';
      if (siteLower.includes('airbnb')) return 'airbnb';
      if (siteLower.includes('booking')) return 'booking';
      if (siteLower.includes('homeaway')) return 'homeaway';
      if (siteLower.includes('guest-adom')) return 'guestadom';
      if (siteLower.includes('guestadom')) return 'guestadom';
      return 'cancel';
    },
    iconReservation2(type, site) {
      if (type === 'GUEST' || type === 'GUEST_CANCEL') return this.iconReservation(site);
      if (type === 'OWNER') return 'owner';
      return 'blockade';
    },
    isSiteVga() {
      if (!this.reservation.reservationSite) return false;
      return (
        this.reservation.reservationSite.includes('happystay') ||
        this.reservation.reservationSite.includes('guest-adom')
      );
    },

    // ATTENTION ! si les méthodes iconReservation et iconReservation2 étaient nommées de la même manière
    // javaScript serait incapable de retrouver laquelle est laquelle malgrés la différence de nombre de paramètres
  },
};
