<script>
import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

/* COMPOSANTS */
import AkDialog from '@components/general/AkDialog';
import AkInputText from '@components/v2/input/AkInputText';
import AkDropdown from '@components/v2/input/AkDropdown.vue';
import GaView from '@components/v2/layout/GaView';
import GaListView from '@components/v2/layout/GaListView.vue';
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination.vue';
import AkButtonAction from '@components/v2/general/AkButtonAction.vue';
import {Icon} from '@iconify/vue';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import messageMixin from '@mixins/messageMixin';
import accommodationContactConst from '@mixins/const/accommodationContactConst';

/* SERVICES */
import itemLabelService from '@services/itemLabelService';
import accommodationContactService from '@services/accommodationContactService';

export default {
  components: {AkDropdown, Icon, GaView, GaListView, AkDatatablePagination, AkButtonAction, AkInputText, AkDialog},
  mixins: [randomRef, utilsMixin, accommodationContactConst, messageMixin],
  metaInfo() {
    return {
      title: 'settings.contactTypes',
    };
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      loading: true,
      currentTab: 'CONTACT_INTERNAL',
      dataList: [],
      item: {},
    };
  },
  validations() {
    return {
      item: {
        label: {required},
        type: {required},
      },
    };
  },
  computed: {
    list() {
      return this.dataList.filter((contact) => contact.type === this.currentTab);
    },
  },
  mounted() {
    accommodationContactService.contactTypes().then((data) => {
      this.dataList = data;
      this.loading = false;
    });
  },
  methods: {
    changeTab(tab) {
      this.currentTab = tab;
    },
    create() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.$refs.dialogCreate.init();
        return;
      }

      this.loading = true;
      itemLabelService
        .create(this.item)
        .then((data) => {
          this.dataList.push(data);
          this.success(
            this.$t(data.type === 'CONTACT_INTERNAL' ? 'contactTypes.internal_added' : 'contactTypes.external_added'),
          );
        })
        .finally(() => {
          this.loading = false;
          this.$refs.dialogCreate.hide();
        });
    },
    update() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.$refs.dialogEdit.init();
        return;
      }

      this.loading = true;
      itemLabelService
        .update(this.item)
        .then((data) => {
          this.replaceInListById(this.dataList, data);
          this.success(
            this.$t(
              data.type === 'CONTACT_INTERNAL' ? 'contactTypes.internal_updated' : 'contactTypes.external_updated',
            ),
          );
        })
        .finally(() => {
          this.loading = false;
          this.$refs.dialogEdit.hide();
        });
    },
    delete() {
      itemLabelService
        .delete(this.item)
        .then((data) => {
          this.removeInListById(this.dataList, data.id);
          this.success(data.label + ' a bien été supprimé');
        })
        .catch((e) => {
          if (e.response.status === 406) {
            this.error("Le type de contact ne peut être supprimée parce qu'elle est déjà utilisée");
          } else {
            this.error(this.$t('unknow_error'));
          }
        });
    },
    openCreateDialog() {
      this.item = {};
      this.item.type = this.currentTab;
      this.$refs.dialogCreate.show();
    },
    openEditDialog(item) {
      this.item = {...item};
      this.$refs.dialogEdit.show();
    },
    openDeleteDialog(item) {
      this.item = item;
      this.$refs.dialogDelete.show();
    },
  },
};
</script>

<template>
  <GaView>
    <template #action>
      <AkButtonAction :label="$t('add')" little @click="openCreateDialog()" />
    </template>
    <template #content>
      <GaListView>
        <template #tabs>
          <div class="flex items-center">
            <span
              :class="[{active: currentTab === 'CONTACT_INTERNAL'}]"
              class="list-table-item"
              @click="changeTab('CONTACT_INTERNAL')"
              >{{ $t('accommodationContact.internal') }}</span
            >
            <span
              :class="[{active: currentTab === 'CONTACT_EXTERNAL'}]"
              class="list-table-item"
              @click="changeTab('CONTACT_EXTERNAL')"
              >{{ $t('accommodationContact.external') }}</span
            >
          </div>
        </template>
        <template #content>
          <AkDatatablePagination :lazy="false" :loading="loading" :value="list">
            <template #empty>
              {{ $t('contactTypes.empty') }}
            </template>
            <Column :header="$t('item_label.label')" field="label" />
            <Column :header="$t('item_label.type')">
              <template #body="slotProps">
                {{ displayForAccommodationContactKindConst(slotProps.data.type) }}
              </template>
            </Column>
            <Column style="width: 100px">
              <template #body="slotProps">
                <div class="flex justify-end gap-2">
                  <span class="hover:cursor-pointer" @click="openEditDialog(slotProps.data)">
                    <i class="ga-icon ga-edit text-2xl text-primary" />
                  </span>
                  <span class="hover:cursor-pointer" @click="openDeleteDialog(slotProps.data)">
                    <i class="ga-icon ga-trash text-2xl text-redGa" />
                  </span>
                </div>
              </template>
            </Column>
          </AkDatatablePagination>
        </template>
      </GaListView>
    </template>

    <template #extra>
      <AkDialog
        ref="dialogCreate"
        :title="$t('contactTypes.add')"
        :validate-label="$t('add')"
        width="450px"
        @validate="create()">
        <div class="flex flex-col gap-2">
          <AkDropdown
            v-model="item.type"
            :label="$t('contactTypes.type')"
            :options="accommodationContactKindConst"
            :validator="v$.item.type" />
          <AkInputText v-model="item.label" :label="$t('contactTypes.label')" :validator="v$.item.label" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogEdit"
        :title="$t('contactTypes.update')"
        :validate-label="$t('update')"
        width="450px"
        @validate="update()">
        <div class="flex flex-col gap-2">
          <AkDropdown
            v-model="item.type"
            :label="$t('contactTypes.type')"
            :options="accommodationContactKindConst"
            :validator="v$.item.type" />
          <AkInputText v-model="item.label" :label="$t('contactTypes.label')" :validator="v$.item.label" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogDelete"
        :auto-hide-on-validate="true"
        :cancel-label="$t('no')"
        :title="$t('delete_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.delete()">
        <div class="flex items-center gap-2">
          <Icon class="text-redGa" icon="fluent:warning-20-regular" width="26" />
          <span v-if="this.item.type === 'CONTACT_INTERNAL'">{{ $t('contactTypes.internal_confirm_delete') }}</span>
          <span v-if="this.item.type === 'CONTACT_EXTERNAL'">{{ $t('contactTypes.external_confirm_delete') }}</span>
        </div>
      </AkDialog>
    </template>
  </GaView>
</template>
