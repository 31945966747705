<script>
/* COMPOSANTS */
import AkInputText from '@components/v2/input/AkInputText.vue';
import AkDropdown from '@components/v2/input/AkDropdown.vue';
import AkChips from '@components/input/AkChips.vue';
import {Icon} from '@iconify/vue';

/* MIXINS */
import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';
import messageMixin from '@mixins/messageMixin';
import roleKeysConst from '@mixins/const/roleKeysConst';
import roleTypesConst from '@mixins/const/roleTypesConst';
import civilitiesConst from '@mixins/const/civilitiesConst';
import taskTypeConst from '@mixins/const/taskTypeConst';

/* SERVICES */
import employeeService from '@services/employeeService';
import taskService from '@services/taskService';
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination.vue';
import taskTaskgroupService from '@services/taskTaskgroupService';
import AkDialog from '@components/general/AkDialog.vue';
import AkLabel from '@components/v2/general/AkLabel.vue';
import AkButtonAction from '@components/v2/general/AkButtonAction.vue';

export default {
  components: {
    AkButtonAction,
    AkLabel,
    Icon,
    AkDialog,
    AkDatatablePagination,
    AkChips,
    AkDropdown,
    AkInputText,
  },
  mixins: [accommodationAutocompleteMixin, messageMixin, roleKeysConst, roleTypesConst, civilitiesConst, taskTypeConst],
  props: {
    taskId: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      list: [],
      loading: true,
      sidebarVisible: false,
      task: {},
      link: {},
    };
  },
  emits: ['creation', 'openEditDialog'],
  methods: {
    refresh() {
      this.loading = true;
      taskService.task(this.taskId).then((data) => {
        this.task = data;
        this.loading = false;
      });
    },
    show() {
      this.sidebarVisible = true;
    },
    hide() {
      this.sidebarVisible = false;
    },
    create() {
      this.v$.employee.$touch();
      if (this.v$.employee.$error) return;
      employeeService
        .create(this.employee)
        .then(() => {
          this.success(this.$t('employee.added'));
          this.employee = {};
          this.$emit('creation');
          this.hide();
        })
        .catch((e) => {
          this.$refs.sidebarMessages.error(this.$t('error.' + e.response.data.error));
        });
    },
    openDissociateDialog(link) {
      this.link = link;
      this.$refs.dialogDissociateGroup.show();
    },
    dissociateGroup() {
      this.loading = true;
      taskTaskgroupService
        .delete(this.link)
        .then(() => {
          this.refresh();
          this.success(this.$t('task.groupDissociated'));
        })
        .catch((e) => {
          let content = 'Une erreur est survenue durant la suppression';
          if (e.response) {
            content = 'Une erreur est survenue durant la suppression : ' + e.response.data.error;
          }
          this.error(content);
        })
        .finally(() => {
          this.$refs.dialogDissociateGroup.hide();
          this.loading = false;
        });
    },
    emitOpenEditDialog() {
      this.$emit('openEditDialog', this.task);
      this.hide();
    },
  },
  computed: {
    isRating() {
      return this.task.type === 'RATING';
    },
    hasOptions() {
      return this.task.type === 'SIMPLE_OPTION' || this.task.type === 'MULTI_OPTION';
    },
  },
  watch: {
    taskId(e) {
      if (e && this.sidebarVisible) this.refresh();
    },
  },
};
</script>

<template>
  <Sidebar :baseZIndex="9998" :visible="sidebarVisible" block-scroll position="right">
    <template #container>
      <div class="flex flex-col sm:flex-row sm:items-center sm:justify-between">
        <div class="p-sidebar-header-content">{{ $t('task.item') }}</div>
        <div class="flex items-center justify-end gap-2">
          <AkButtonAction :label="$t('task.update')" little @click="emitOpenEditDialog" />
          <button type="button" @click="hide">
            <span class="ga-icon ga-close_circle text-4xl text-gray hover:text-primary hover:bg-transparent" />
          </button>
        </div>
      </div>
      <div>
        <perfect-scrollbar class="h-full">
          <div class="flex flex-col gap-4">
            <AkInputText v-model="task.label" :disabled="true" :label="$t('task.label')" />
            <AkDropdown v-model="task.type" :disabled="true" :label="$t('task.type')" :options="taskTypeConst" />
            <AkInputText v-if="isRating" v-model="task.typeData.rating" label="Echelle (consigne)" />
            <AkChips v-if="hasOptions" v-model="task.typeData.options" label="Choix" />

            <template v-if="task.groups && task.groups.length > 0">
              <AkLabel>
                {{ $t('task.groupsAssociated') }}
              </AkLabel>
              <div>
                <AkDatatablePagination :lazy="false" :value="task.groups" :loading="loading">
                  <Column :header="$t('taskgroup.label')">
                    <template #body="slotProps">
                      {{ slotProps.data.taskgroupLabel }}
                    </template>
                  </Column>
                  <Column>
                    <template #body="slotProps">
                      <div class="flex justify-end gap-2">
                        <span class="hover:cursor-pointer" @click="openDissociateDialog(slotProps.data)">
                          <i class="ga-icon ga-trash text-2xl text-redGa" />
                        </span>
                      </div>
                    </template>
                  </Column>
                </AkDatatablePagination>
              </div>
            </template>
          </div>
        </perfect-scrollbar>
      </div>
    </template>
  </Sidebar>

  <AkDialog
    ref="dialogDissociateGroup"
    :cancel-label="$t('no')"
    :title="$t('dissociate_dialog')"
    :validate-label="$t('yes')"
    width="450px"
    @validate="this.dissociateGroup()">
    <div class="flex items-center gap-2">
      <Icon class="text-redGa" icon="fluent:warning-20-regular" width="26" />
      <span>{{ $t('task.confirm_dissociategroup') }}</span>
    </div>
  </AkDialog>
</template>
