<script>
import AkLabel from '@components/v2/general/AkLabel';
import FileUpload from 'primevue/fileupload';
import prettyBytes from 'pretty-bytes';
import envConst from '@mixins/const/envConst';

export default {
  components: {AkLabel, FileUpload},
  mixins: [envConst],
  inject: {pSubmitted: {default: null}, pDisabled: {default: null}},
  props: {
    label: {
      type: String,
      required: false,
    },
    modelValue: {
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    className: {
      type: String,
      required: false,
      default: '',
    },
    accept: {
      type: String,
      required: false,
      default: '',
    },
    validator: {
      type: Object,
      required: false,
      default: undefined,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    ignoredValidator: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      id: null,
    };
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    hasCurrentValue() {
      return this.currentValue !== undefined && this.currentValue !== null;
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    hasValidator() {
      return this.validator !== undefined;
    },
    isRequired() {
      return (
        this.hasValidator &&
        this.validator.required !== undefined &&
        (this.validator.required.$params.type === 'required' ||
          (this.validator.required.$params.type === 'requiredIf' && this.validator.required.$params.prop === true))
      );
    },
    isRequiredFailed() {
      return (
        this.isRequired &&
        this.ignoredValidator.indexOf('required') === -1 &&
        this.validator.required.$invalid &&
        this.alreadySubmitted
      );
    },
  },
  methods: {
    fileSize(f) {
      return prettyBytes(f.size, {locale: 'en', maximumFractionDigits: 1});
    },
    selectFile(event) {
      this.currentValue = event.files;
    },
    deleteFile(idx) {
      this.currentValue.splice(idx, 1);
    },
    clear() {
      this.$refs.uploader.clear();
    },
    uploadFile() {
      this.$refs.uploader.$refs.fileInput.click();
    },
  },
};
</script>

<template>
  <div class="w-full input-layout form-control" :class="[className]">
    <AkLabel :required="this.isRequired">
      {{ label }}
    </AkLabel>
    <FileUpload
      ref="uploader"
      :invalidFileSizeMessage="$t('file.err_size')"
      invalidFileTypeMessage="Ce type est interdit"
      :maxFileSize="getMaxFileUpload()"
      :multiple="multiple"
      :accept="accept"
      :disabled="isDisabled"
      @select="selectFile">
      <template #header>
        <div></div>
      </template>
      <template #content="{files}">
        <div class="flex flex-col gap-2" v-for="(file, index) of files" :key="file.name + file.type + file.size">
          <div class="flex flex-row gap-2 items-center p-2 rounded-lg">
            <div>
              <i class="ga-icon ga-document text-gray text-3xl" />
            </div>
            <div class="truncate flex flex-col flex-1 hover:cursor-pointer gap-1">
              <span class="pointer text-primary font-medium truncate text-base">{{ file.name }}</span>
              <span class="text-gray font-normal text-sm">{{ fileSize(file) }}</span>
            </div>
            <span class="hover:cursor-pointer" @click="deleteFile(index)"
              ><i class="ga-icon ga-trash text-redGa text-2xl"
            /></span>
          </div>
        </div>
        <div
          v-if="files.length > 0"
          class="flex flex-col justify-center items-center gap-1 p-10 border-dashed rounded-xl text-center border border-grayBg hover:cursor-pointer"
          @click="uploadFile">
          <i class="ga-icon ga-upload text-3xl text-dark" />
          <p class="text-base font-normal text-dark">Faites glisser un fichier ici</p>
          <p class="text-base font-normal text-dark">
            Ou <span class="text-primary">importer depuis votre ordinateur</span>
          </p>
        </div>
      </template>
      <template #empty>
        <div
          :class="[{'fileempty-invalid': isRequiredFailed && alreadySubmitted}]"
          class="flex flex-col justify-center items-center gap-1 p-10 border-dashed rounded-xl text-center border border-grayBg hover:cursor-pointer"
          @click="uploadFile">
          <i class="ga-icon ga-upload text-3xl text-dark" />
          <p class="text-base font-normal text-dark">Faites glisser un fichier ici</p>
          <p class="text-base font-normal text-dark">
            Ou <span class="text-primary">importer depuis votre ordinateur</span>
          </p>
        </div>
      </template>
    </FileUpload>
    <div class="flex flex-col flex-1">
      <small v-if="isRequiredFailed" class="p-error">
        {{ $t('field_required', [this.label]) }}
      </small>
    </div>
  </div>
</template>
