<script>
/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';

export default {
  mixins: [utilsMixin],
  props: {
    value: {
      type: [Number],
      required: true,
    },
    abs: {
      type: [Boolean],
      required: false,
      default: false,
    },
    red: {
      type: [Boolean],
      required: false,
      default: false,
    },
    green: {
      type: [Boolean],
      required: false,
      default: false,
    },
    inputClass: {
      type: String,
      required: false,
      default: 'text-base font-normal',
    },
  },

  computed: {
    colorClass() {
      if (this.red) return 'text-redGa';
      if (this.green) return 'text-greenGa';
      return this.value > 0 ? 'text-greenGa' : 'text-redGa';
    },
    buildValue() {
      if (!this.abs) return this.value;
      return Math.abs(this.value);
    },
  },
};
</script>

<template>
  <span v-if="value !== undefined" :class="[inputClass, colorClass]">
    {{ formatCurrencyEUR(buildValue) }}
  </span>
</template>
