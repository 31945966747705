<script>
import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

/* COMPOSANTS */
import AkInputText from '@components/v2/input/AkInputText';
import AkCheckbox from '@components/v2/input/AkCheckbox';
import AkDialog from '@components/general/AkDialog';
import GaView from '@components/v2/layout/GaView';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import itemLabelService from '@services/itemLabelService';
import messageMixin from '@mixins/messageMixin';
import AkButtonAction from '../../components/v2/general/AkButtonAction.vue';
import GaListView from '../../components/v2/layout/GaListView.vue';
import AkDatatablePagination from '../../components/v2/general/AkDatatablePagination.vue';
import {Icon} from '@iconify/vue';

export default {
  components: {Icon, AkDatatablePagination, GaListView, AkButtonAction, GaView, AkInputText, AkCheckbox, AkDialog},
  mixins: [randomRef, utilsMixin, messageMixin],
  setup() {
    return {v$: useVuelidate()};
  },
  metaInfo() {
    return {
      title: 'settings.achat_hote_types',
    };
  },
  data() {
    return {
      loading: true,
      list: [],
      item: {
        type: 'ACHAT_HOTE',
        enabled: true,
        label: '',
      },
    };
  },
  validations() {
    return {
      item: {
        label: {required},
      },
    };
  },
  mounted() {
    itemLabelService.itemLabels({type: 'ACHAT_HOTE'}).then((data) => {
      this.list = data;
      this.loading = false;
    });
  },
  methods: {
    create() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.$refs.dialogCreate.init();
        return;
      }

      itemLabelService
        .create(this.item)
        .then((data) => {
          this.success(this.$t('item_label.achat_hote_type_added'));
          this.list.push(data);
        })
        .finally(() => {
          this.$refs.dialogCreate.hide();
        });
    },
    update() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.$refs.dialogEdit.init();
        return;
      }

      itemLabelService
        .update(this.item)
        .then((data) => {
          this.replaceInListById(this.list, data);
          this.success(this.$t('item_label.achat_hote_type_updated'));
        })
        .finally(() => {
          this.$refs.dialogEdit.hide();
        });
    },
    delete() {
      itemLabelService
        .delete(this.item)
        .then((data) => {
          this.removeInListById(this.list, data.id);
          this.success(this.item.label + ' a bien été supprimé');
        })
        .catch((e) => {
          let content = 'Une erreur est survenue durant la suppression';
          if (e.response) {
            content = 'Une erreur est survenue durant la suppression : ' + e.response.data.error;
          }
          this.addError(content);
        })
        .finally(() => {
          this.$refs.dialogDelete.hide();
        });
    },

    openCreateDialog() {
      this.item = {
        type: 'ACHAT_HOTE',
        enabled: true,
        label: '',
      };
      this.$refs.dialogCreate.show();
    },
    openEditDialog(item) {
      this.item = {...item};
      this.$refs.dialogEdit.show();
    },
    openDeleteDialog(item) {
      this.item = item;
      this.$refs.dialogDelete.show();
    },
    reorder(event) {
      const item = this.list[event.dragIndex];
      this.loading = true;
      itemLabelService.reorderPosition(item, event.dropIndex - event.dragIndex).then(() => {
        this.reorderInListByIdAndDelta(this.list, item.id, event.dropIndex - event.dragIndex);
        this.loading = false;
      });
    },
  },
};
</script>

<template>
  <GaView :ref="ref" :title="$t('settings.achat_hote_types')">
    <template #action>
      <div class="flex justify-end">
        <AkButtonAction little :label="$t('add')" @click="openCreateDialog()"> </AkButtonAction>
      </div>
    </template>

    <template #content>
      <GaListView>
        <template #content>
          <AkDatatablePagination can-change-order @row-reorder="reorder" :loading="loading" :value="list" :lazy="false">
            <template #empty>
              {{ $t('item_label.achat_hote_type_empty') }}
            </template>
            <Column :header="$t('item_label.label')" field="label">
              <template #body="slotProps">
                <span>{{ slotProps.data.label }}</span>
              </template>
            </Column>
            <Column :header="$t('item_label.enabled')" field="enabled" style="width: 80px" class="text-center">
              <template #body="slotProps">
                <i v-if="slotProps.data.enabled" class="ga-icon ga-check_circle text-primary text-2xl" />
                <i v-else class="ga-icon ga-close_circle text-gray text-2xl" />
              </template>
            </Column>
            <Column style="width: 100px">
              <template #body="slotProps">
                <div class="flex justify-end gap-2">
                  <span class="hover:cursor-pointer" @click="openEditDialog(slotProps.data)">
                    <i class="ga-icon ga-edit text-2xl text-primary" />
                  </span>
                  <span class="hover:cursor-pointer" @click="openDeleteDialog(slotProps.data)">
                    <i class="ga-icon ga-trash text-2xl text-redGa" />
                  </span>
                </div>
              </template>
            </Column>
          </AkDatatablePagination>
        </template>
      </GaListView>
    </template>

    <template #extra>
      <AkDialog
        ref="dialogCreate"
        :title="$t('item_label.achat_hote_type')"
        :validate-label="$t('add')"
        width="450px"
        @validate="create()">
        <div class="flex flex-col gap-2">
          <AkInputText v-model="item.label" :label="$t('item_label.label')" :validator="v$.item.label" />
          <AkCheckbox v-model="item.enabled" :label="$t('item_label.enabled')" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogEdit"
        :title="$t('item_label.achat_hote_type')"
        :validate-label="$t('update')"
        width="450px"
        @validate="update()">
        <div class="flex flex-col gap-2">
          <AkInputText v-model="item.label" :label="$t('item_label.label')" :validator="v$.item.label" />
          <AkCheckbox v-model="item.enabled" :label="$t('item_label.enabled')" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogDelete"
        :cancel-label="$t('no')"
        :title="$t('delete_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.delete()">
        <div class="flex items-center gap-2">
          <Icon icon="fluent:warning-20-regular" class="text-redGa" width="26" />
          <span class="text-dark font-normal">{{ $t('item_label.confirm_delete_achat_hote_type') }}</span>
        </div>
      </AkDialog>
    </template>
  </GaView>
</template>
