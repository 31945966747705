<script>
/* COMPOSANTS */
import AkInputText from '@components/v2/input/AkInputText.vue';
import {Icon} from '@iconify/vue';

/* MIXINS */
import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';
import messageMixin from '@mixins/messageMixin';
import roleKeysConst from '@mixins/const/roleKeysConst';
import roleTypesConst from '@mixins/const/roleTypesConst';
import civilitiesConst from '@mixins/const/civilitiesConst';
import taskTypeConst from '@mixins/const/taskTypeConst';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import employeeService from '@services/employeeService';
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination.vue';
import taskTaskgroupService from '@services/taskTaskgroupService';
import AkDialog from '@components/general/AkDialog.vue';
import AkLabel from '@components/v2/general/AkLabel.vue';
import taskgroupService from '@services/taskgroupService';
import AkButtonAction from '@components/v2/general/AkButtonAction.vue';

export default {
  components: {
    AkButtonAction,
    AkLabel,
    Icon,
    AkDialog,
    AkDatatablePagination,
    AkInputText,
  },
  mixins: [
    accommodationAutocompleteMixin,
    messageMixin,
    roleKeysConst,
    roleTypesConst,
    civilitiesConst,
    taskTypeConst,
    utilsMixin,
  ],
  props: {
    taskgroupId: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      list: [],
      loading: true,
      sidebarVisible: false,
      taskgroup: {},
      link: {},
    };
  },
  emits: ['creation', 'openEditDialog'],
  methods: {
    refresh() {
      this.loading = true;
      taskgroupService.taskgroup(this.taskgroupId).then((data) => {
        this.taskgroup = data;
        this.loading = false;
      });
    },
    show() {
      this.sidebarVisible = true;
    },
    hide() {
      this.sidebarVisible = false;
    },
    create() {
      this.v$.employee.$touch();
      if (this.v$.employee.$error) return;
      employeeService
        .create(this.employee)
        .then(() => {
          this.success(this.$t('employee.added'));
          this.employee = {};
          this.$emit('creation');
          this.hide();
        })
        .catch((e) => {
          this.$refs.sidebarMessages.error(this.$t('error.' + e.response.data.error));
        });
    },
    openDissociateDialog(link) {
      this.link = link;
      this.$refs.dialogDissociateGroup.show();
    },
    dissociateTask() {
      this.loading = true;
      taskTaskgroupService
        .delete(this.link)
        .then(() => {
          this.refresh();
          this.success(this.$t('taskgroup.taskDissociated'));
        })
        .catch((e) => {
          let content = 'Une erreur est survenue durant la suppression';
          if (e.response) {
            content = 'Une erreur est survenue durant la suppression : ' + e.response.data.error;
          }
          this.addError(content);
        })
        .finally(() => {
          this.displayDissociateDialog = false;
          this.loading = false;
        });
    },
    increase(link) {
      taskTaskgroupService.increase(link).then(() => {
        this.increaseInListById(this.taskgroup.tasks, link.id);
      });
    },
    decrease(link) {
      taskTaskgroupService.decrease(link).then(() => {
        this.decreaseInListById(this.taskgroup.tasks, link.id);
      });
    },
    emitOpenEditDialog() {
      this.$emit('openEditDialog', this.taskgroup);
      this.hide();
    },
  },
  watch: {
    taskgroupId(e) {
      if (e && this.sidebarVisible) this.refresh();
    },
  },
};
</script>

<template>
  <Sidebar :baseZIndex="9998" :visible="sidebarVisible" block-scroll position="right">
    <template #container>
      <div class="flex flex-col sm:flex-row sm:items-center sm:justify-between">
        <div class="p-sidebar-header-content">{{ $t('taskgroup.item') }}</div>
        <div class="flex items-center justify-end gap-2">
          <AkButtonAction :label="$t('taskgroup.update')" little @click="emitOpenEditDialog" />
          <button type="button" @click="hide">
            <span class="ga-icon ga-close_circle text-4xl text-gray hover:text-primary hover:bg-transparent" />
          </button>
        </div>
      </div>
      <perfect-scrollbar class="h-full">
        <div class="flex flex-col gap-4">
          <AkInputText v-model="taskgroup.label" :disabled="true" :label="$t('taskgroup.label')" />

          <template v-if="taskgroup.tasks && taskgroup.tasks.length > 0">
            <AkLabel>
              {{ $t('taskgroup.tasksAssociated') }}
            </AkLabel>
            <div>
              <AkDatatablePagination :lazy="false" :value="taskgroup.tasks" :loading="loading">
                <Column :header="$t('task.position')" header-style=" width: 80px;">
                  <template #body="slotProps">
                    <div class="gap-2 flex">
                      <span
                        v-if="slotProps.index !== 0"
                        class="btn-table xs hover:cursor-pointer"
                        @click="decrease(slotProps.data)">
                        <i class="ga-icon ga-long_arrow_up" />
                      </span>
                      <span v-else class="flex-1"></span>
                      <span
                        v-if="slotProps.index !== taskgroup.tasks.length - 1"
                        class="btn-table xs hover:cursor-pointer"
                        @click="increase(slotProps.data)">
                        <i class="ga-icon ga-long_arrow_down" />
                      </span>
                    </div>
                  </template>
                </Column>
                <Column :header="$t('task.label')" field="taskLabel" />
                <Column body-style="text-align: right; overflow: visible" header-style=" width: 160px; text-align: btn">
                  <template #body="slotProps">
                    <div class="flex justify-end gap-2">
                      <span class="hover:cursor-pointer" @click="openDissociateDialog(slotProps.data)">
                        <i class="ga-icon ga-trash text-2xl text-redGa" />
                      </span>
                    </div>
                  </template>
                </Column>
              </AkDatatablePagination>
            </div>
          </template>
        </div>
      </perfect-scrollbar>
    </template>
  </Sidebar>

  <AkDialog
    ref="dialogDissociateGroup"
    :cancel-label="$t('no')"
    :title="$t('dissociate_dialog')"
    :validate-label="$t('yes')"
    width="450px"
    @validate="this.dissociateTask()">
    <div class="flex items-center gap-2">
      <Icon class="text-redGa" icon="fluent:warning-20-regular" width="26" />
      <span>{{ $t('taskgroup.confirm_dissociatetask') }}</span>
    </div>
  </AkDialog>
</template>
